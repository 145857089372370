
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import videojs from 'video.js'
import { VehicleDetail } from '../../types/vehicle'
import { drawPolygon } from '@/utils/formatData'

require('video.js/dist/video-js.css')

@Component({
  filters: {
    filtersColor (value: string) {
      return value === '蓝牌' ? 'blue' : value === '黄牌' ? 'yellow' : value === '新能源' ? 'green' : 'gray'
    }
  }
})
export default class MonitorDetail extends Vue {
  private map: AMap.Map | any = ''
  private onlineImg = require('@/assets/icon/vehicle/icon_map_cheliangjiankong_zaixian.svg')
  private onlineImgCursor = require('@/assets/icon/vehicle/icon_map_cheliangjiankong_zaixian_dianji.svg')
  private offImg = require('@/assets/icon/vehicle/icon_map_cheliangjiankong_lixian.svg')
  private offImgCursor = require('@/assets/icon/vehicle/icon_map_cheliangjiankong_lixian_dianji.svg')
  private drawer = false
  private drawerData = {
    deviceId: '',
    deviceStatus: '2',
    deviceTypeName: '',
    deviceNumber: ''
  }

  private captureData = {}

  private onlineAmount = ''
  private offlineAmount = ''
  private videoPlay: any = null
  private videoHtml = ''
  private setInter: number | null = null // 定时器

  get projectId () {
    return this.$route.params.projectId || ''
  }

  created () {
    if (this.projectId) {
      this.getProjectLocation()
    } else {
      this.$router.push({ name: 'vehicleList' })
    }
  }

  // 监听页面离开，销毁视频、地图
  destroyed () {
    this.setInter && clearInterval(this.setInter)
    if (this.videoPlay) {
      this.videoPlay.dispose()
    }
    if (this.map) {
      this.map.off('click', () => {
        this.drawerClose()
      })
      this.map.destroy()
    }
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.loadMap(res.projectInfo, res.projectLocation)
    })
  }

  // 获取地图设备列表
  getCameraList () {
    this.$axios.get(this.$apis.vehicle.selectVehicle, {
      projectId: this.projectId
    }).then(res => {
      this.onlineAmount = res.onlineAmount || 0
      this.offlineAmount = res.offLineAmount || 0
      this.drawEchart()
      this.setMarker(res.devices)
    })
  }

  // 获取车辆信息
  getCaptureData (deviceId: string) {
    this.$axios.get(this.$apis.vehicle.selectLatestVehicleData, { deviceId }).then(res => {
      this.captureData = res
    })
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.map.on('click', this.drawerClose)
      drawPolygon(this.map, list || [], null, this.drawerClose)
      this.getCameraList()
    })
  }

  setMarker (devices: VehicleDetail[]) {
    // 循坏将设备marker添加到地图
    devices.map((item: VehicleDetail) => {
      const icon = item.deviceStatus === '1' ? this.onlineImg : this.offImg
      const marker: any = new AMap.Marker({
        icon: new AMap.Icon({
          // 图标的取图地址
          image: icon
        }),
        offset: new AMap.Pixel(-18, -48),
        position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
      })
      marker.detail = item
      marker.on('click', (e: any) => {
        if (this.drawerData.deviceId !== e.target.detail.deviceId) {
          if (this.drawer) {
            this.drawerClose()
          }
          this.drawerData = e.target.detail
          // 点击切换大图标
          const iconClick = item.deviceStatus === '1' ? this.onlineImgCursor : this.offImgCursor
          e.target.setOffset(new AMap.Pixel(-22, -64))
          e.target.setIcon(iconClick)
          console.log(e.target.detail)
          // 渲染video标签
          this.videoHtml = `<video
            id="videoPlay"
            ref="videoPlayer"
            class="video-js vjs-default-skin vjs-big-play-centered"
            controls
            autoplay
            muted
            preload="auto"
            style="width: 100%;height: 100%;"
          >
            <source src="${e.target.detail.addressLive}" type="application/x-mpegURL" />
          </video>`
          // 播放视频流
          this.$nextTick(() => {
            const options: any = {
              bigPlayButton: false,
              textTrackDisplay: false,
              posterImage: true,
              errorDisplay: false,
              controlBar: true
            }
            this.videoPlay = videojs('videoPlay', options)
            this.videoPlay.play()
          })
          if (e.target.detail.deviceStatus === '1') {
            this.getCaptureData(e.target.detail.deviceId)
            this.setInter = setInterval(() => {
              this.getCaptureData(e.target.detail.deviceId)
            }, 30000)
          } else {
            this.captureData = {}
          }
        }
        this.drawer = true
      })
      this.map.add(marker)
    })
  }

  // 抽屉关闭
  drawerClose () {
    this.drawer = false
    // 将图标替换
    for (let i = 0; i < this.map.getAllOverlays('marker').length; i++) {
      if (this.drawerData.deviceNumber === this.map.getAllOverlays('marker')[i].detail.deviceNumber) {
        const icon = this.map.getAllOverlays('marker')[i].detail.deviceStatus === '1'
          ? this.onlineImg
          : this.offImg
        this.map.getAllOverlays('marker')[i].setOffset(new AMap.Pixel(-18, -48))
        this.map.getAllOverlays('marker')[i].setIcon(icon)
      }
    }
    // 清空抽屉
    this.videoPlay.dispose()
    this.videoHtml = ''
    this.drawerData = {
      deviceId: '',
      deviceStatus: '2',
      deviceTypeName: '',
      deviceNumber: ''
    }
    this.setInter && clearInterval(this.setInter)
  }

  // 环形图
  drawEchart () {
    const pieEchart: any = echarts.init(this.$refs.echarts as any)
    const option = {
      title: {
        text: '状态统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: ['#1AD1B0', 'rgb(210, 210, 210)'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${this.onlineAmount}` : `${item}  ${this.offlineAmount}`
        }
      },

      series: [{
        name: 'Line 1',
        type: 'pie',
        clockWise: true,
        radius: ['38%', '65%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: [{
          name: '在线',
          value: this.onlineAmount
        }, {
          name: '离线',
          value: this.offlineAmount
        }]
      }]
    }
    pieEchart.setOption(option)
  }
}
